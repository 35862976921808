import { Action } from 'redux';

import {
	DashboardInput,
	Dashboard,
	MutationDeleteDashboardByIdArgs,
	DashboardComponent,
	DashboardComponentInput,
	DashboardContentInput,
} from '@typings/schema.gen';
import { Camera, Sign } from '@typings/tg-types';
import { AppPages, RequestStatus } from '@src/constants';
import { apiConfig, enabledModulesConfig } from '@config';

import { ThunkActionRoot } from '@src/store';
import { navigatePage } from '@src/actions/routing';
import fetchGql, { handleGraphQLErrors } from '@src/util/fetchGql';
import gql from '@src/util/gql';
import fragments from '@src/actions/query-fragments';
import tryFetch from '@src/util/tryFetch';

export const SET_DASHBOARDS = 'SET_DASHBOARDS';
export const SET_GET_DASHBOARD_STATUS = 'SET_GET_DASHBOARD_STATUS';
export const SET_GET_ONE_DASHBOARD_STATUS = 'SET_GET_ONE_DASHBOARD_STATUS';
export const SET_MUTATE_DASHBOARD_STATUS = 'SET_MUTATE_DASHBOARD_STATUS';
export const SET_EDIT_DASHBOARD_COMPONENT = 'SET_EDIT_DASHBOARD_COMPONENT';

export const SET_MUTATE_DASHBOARD = 'SET_MUTATE_DASHBOARD';
export const DELETE_DASHBOARD = 'DELETE_DASHBOARD';

export const SET_GET_CAMERAS = 'SET_GET_CAMERAS';
export const SET_GET_SIGNS = 'SET_GET_SIGNS';

export const SET_ALL_CAMERA_VIEWS = 'SET_ALL_CAMERAS';
export const SET_ALL_SIGNS = 'SET_ALL_SIGNS';

export const CLEAR_EDIT = 'CLEAR_EDIT';

interface SetDashboards extends Action<typeof SET_DASHBOARDS> {
	dashboards: Dashboard[];
}

interface SetGetOneDashboardStatus extends Action<typeof SET_GET_ONE_DASHBOARD_STATUS> {
	status: RequestStatus;
}

interface SetGetDashboardsStatus extends Action<typeof SET_GET_DASHBOARD_STATUS> {
	status: RequestStatus;
}

interface SetMutateDashboardStatus extends Action<typeof SET_MUTATE_DASHBOARD_STATUS> {
	status: RequestStatus;
}

interface DeleteDashboard extends Action<typeof DELETE_DASHBOARD> {
	id: string;
}

interface SetEditDashboard extends Action<typeof SET_MUTATE_DASHBOARD> {
	dashboard?: Dashboard;
}

interface SetEditDashboardComponent extends Action<typeof SET_EDIT_DASHBOARD_COMPONENT> {
	index: number;
	component: DashboardComponent;
}

interface SetGetSigns extends Action<typeof SET_GET_SIGNS> {
	status: RequestStatus;
}

interface SetGetCameras extends Action<typeof SET_GET_CAMERAS> {
	status: RequestStatus;
}

interface SetAllSigns extends Action<typeof SET_ALL_SIGNS> {
	signs: Sign[];
}

interface SetAllCameras extends Action<typeof SET_ALL_CAMERA_VIEWS> {
	cameras: Camera[];
}

export type ClearEdit = Action<typeof CLEAR_EDIT>;

export type DashboardActions =
	| DeleteDashboard
	| SetDashboards
	| SetGetDashboardsStatus
	| SetGetOneDashboardStatus
	| SetMutateDashboardStatus
	| SetEditDashboard
	| SetEditDashboardComponent
	| SetGetSigns
	| SetGetCameras
	| SetAllSigns
	| SetAllCameras
	| ClearEdit;

const ALL_DASHBOARDS_QUERY = gql`
	query {
		dashboards {
			...Dashboard
		}
	}
	${fragments.dashboard}
`;

const DASHBOARD_BY_ID_QUERY = gql`
	query ($id: ID!) {
		dashboardById(id: $id) {
			...Dashboard
		}
	}
	${fragments.dashboard}
`;

const DELETE_DASHBOARD_MUTATION = gql`
	mutation ($id: ID!) {
		deleteDashboardById(id: $id)
	}
`;

const NEW_DASHBOARD_MUTATION = gql`
	mutation ($input: DashboardInput!) {
		saveDashboard(input: $input) {
			...Dashboard
		}
	}
	${fragments.dashboard}
`;

const UPDATE_DASHBOARD_MUTATION = gql`
	mutation ($input: DashboardInput!) {
		saveDashboard(input: $input) {
			...Dashboard
		}
	}
	${fragments.dashboard}
`;

export const fetchDashboards = (): ThunkActionRoot => async (dispatch) => {
	dispatch({ type: SET_GET_DASHBOARD_STATUS, status: RequestStatus.LOADING });
	try {
		const response = await fetchGql({
			query: ALL_DASHBOARDS_QUERY,
		});
		handleGraphQLErrors(response.errors);
		const dashboards = response.data.dashboards?.filter((m) => m) || [];
		dispatch({ type: SET_DASHBOARDS, dashboards: dashboards as Dashboard[] });
		dispatch({ type: SET_GET_DASHBOARD_STATUS, status: RequestStatus.SUCCESS });
	} catch (error) {
		dispatch({ type: SET_GET_DASHBOARD_STATUS, status: RequestStatus.ERROR });
		console.error(error);
	}
};

export const fetchDashboardById =
	(id: string): ThunkActionRoot =>
	async (dispatch) => {
		dispatch({ type: SET_GET_ONE_DASHBOARD_STATUS, status: RequestStatus.LOADING });
		try {
			const response = await fetchGql({
				query: DASHBOARD_BY_ID_QUERY,
				variables: {
					id,
				},
			});
			const dashboard = response.data.dashboardById;
			if (!dashboard) {
				throw new Error('Requested dashboard not found');
			}
			dispatch({ type: SET_MUTATE_DASHBOARD, dashboard });
			dispatch({ type: SET_GET_ONE_DASHBOARD_STATUS, status: RequestStatus.SUCCESS });
		} catch (error) {
			dispatch({ type: SET_GET_ONE_DASHBOARD_STATUS, status: RequestStatus.ERROR });
			console.error(error);
		}
	};

export const deleteDashboard =
	({ id }: MutationDeleteDashboardByIdArgs): ThunkActionRoot =>
	async (dispatch) => {
		if (!id) {
			throw new Error('Missing id for delete message');
		}
		dispatch({ type: SET_MUTATE_DASHBOARD_STATUS, status: RequestStatus.LOADING });
		try {
			const response = await fetchGql({
				query: DELETE_DASHBOARD_MUTATION,
				variables: {
					id,
				},
			});
			handleGraphQLErrors(response.errors);
			dispatch({ type: DELETE_DASHBOARD, id });
			dispatch({ type: SET_MUTATE_DASHBOARD_STATUS, status: RequestStatus.SUCCESS });
		} catch (error) {
			dispatch({ type: SET_MUTATE_DASHBOARD_STATUS, status: RequestStatus.ERROR });
			console.error(error);
		}
	};

export const submitNewDashboard =
	({ title, icon, components }: DashboardInput): ThunkActionRoot =>
	async (dispatch) => {
		dispatch({ type: SET_MUTATE_DASHBOARD_STATUS, status: RequestStatus.LOADING });
		dispatch({ type: CLEAR_EDIT });
		const cleanComponents = components
			?.filter((c) => !!c)
			.map((component, componentIndex) => {
				const assertedComponent = component as DashboardComponentInput;

				const cleanContents: DashboardContentInput[] | undefined = assertedComponent.contents
					?.filter((c) => !!c)
					.map((content, contentIndex) => {
						const assertedContent = content as DashboardContentInput;

						return {
							id: undefined,
							componentId: undefined,
							type: assertedContent.type,
							configJson: assertedContent.configJson,
							sequenceNumber: contentIndex,
						};
					});

				const cleanComponent: DashboardComponentInput = {
					id: undefined,
					dashboardId: undefined,
					type: assertedComponent.type,
					title: assertedComponent.title,
					priorityThreshold: assertedComponent.priorityThreshold,
					sequenceNumber: componentIndex,
					contents: cleanContents || [],
				};
				return cleanComponent;
			});

		try {
			const input = {
				title,
				icon,
				components: cleanComponents,
			};
			const responseDashboard = await fetchGql({
				query: NEW_DASHBOARD_MUTATION,
				variables: {
					input,
				},
			});

			handleGraphQLErrors(responseDashboard.errors);

			dispatch({
				type: SET_MUTATE_DASHBOARD_STATUS,
				status: RequestStatus.SUCCESS,
			});
			setTimeout(() => {
				void dispatch(navigatePage(`/${AppPages.DASHBOARDS}`));
			}, 1500);
		} catch (error) {
			dispatch({ type: SET_MUTATE_DASHBOARD_STATUS, status: RequestStatus.ERROR });
			console.error(error);
		}
	};

export const updateDashboard =
	({ id, title, icon, components }: DashboardInput): ThunkActionRoot =>
	async (dispatch) => {
		dispatch({ type: SET_MUTATE_DASHBOARD_STATUS, status: RequestStatus.LOADING });
		dispatch({ type: CLEAR_EDIT });

		const cleanComponents = components
			?.filter((c) => !!c)
			.map((component, componentIndex) => {
				const assertedComponent = component as DashboardComponentInput;
				const cleanContents: DashboardContentInput[] | undefined = assertedComponent.contents
					?.filter((c) => !!c)
					.map((content, contentIndex) => {
						const assertedContent = content as DashboardContentInput;

						return {
							id: assertedContent.id,
							componentId: assertedComponent.id,
							type: assertedContent.type,
							configJson: assertedContent.configJson,
							sequenceNumber: contentIndex,
						};
					});
				const cleanComponent: DashboardComponentInput = {
					id: assertedComponent.id,
					dashboardId: id,
					type: assertedComponent.type,
					title: assertedComponent.title,
					priorityThreshold: assertedComponent.priorityThreshold,
					sequenceNumber: componentIndex,
					contents: cleanContents,
				};
				return cleanComponent;
			});

		try {
			const input = {
				id,
				title,
				icon,
				components: cleanComponents,
			};
			const responseDashboard = await fetchGql({
				query: UPDATE_DASHBOARD_MUTATION,
				variables: {
					input,
				},
			});

			handleGraphQLErrors(responseDashboard.errors);

			dispatch({ type: SET_MUTATE_DASHBOARD_STATUS, status: RequestStatus.SUCCESS });
			setTimeout(() => {
				void dispatch(navigatePage(`/${AppPages.DASHBOARDS}`));
			}, 1500);
		} catch (error) {
			dispatch({ type: SET_MUTATE_DASHBOARD_STATUS, status: RequestStatus.ERROR });
			console.error(error);
		}
	};

export const fetchAllCameras = (): ThunkActionRoot => async (dispatch) => {
	if (!enabledModulesConfig.dashboards) {
		return;
	}
	dispatch({ type: SET_GET_CAMERAS, status: RequestStatus.LOADING });
	try {
		const response = await tryFetch(() => fetch(apiConfig.cameras), 3, 1000);
		if (!response.ok) {
			throw new Error(JSON.stringify(response));
		}
		const data = (await response.json()) as Camera[];
		dispatch({ type: SET_ALL_CAMERA_VIEWS, cameras: data });
		dispatch({ type: SET_GET_CAMERAS, status: RequestStatus.SUCCESS });
	} catch (error) {
		dispatch({ type: SET_GET_CAMERAS, status: RequestStatus.ERROR });
		console.error(error);
	}
};

export const fetchAllSigns = (): ThunkActionRoot => async (dispatch) => {
	if (!enabledModulesConfig.dashboards) {
		return;
	}
	dispatch({ type: SET_GET_SIGNS, status: RequestStatus.LOADING });
	try {
		const response = await tryFetch(() => fetch(apiConfig.signs), 3, 1000);
		if (!response.ok) {
			throw new Error(JSON.stringify(response));
		}
		const data = (await response.json()) as Sign[];

		dispatch({ type: SET_ALL_SIGNS, signs: data });
		dispatch({ type: SET_GET_SIGNS, status: RequestStatus.SUCCESS });
	} catch (error) {
		dispatch({ type: SET_GET_SIGNS, status: RequestStatus.ERROR });
		console.error(error);
	}
};
