import { Action } from 'redux';

import { ThunkActionRoot } from '../store';
import { RequestStatus } from '../constants';

import { User } from '../../typings/schema.gen';

import getAuthUser, { LSAuthUser, decodeAuthToken } from './getAuthUser';
import navigateModal from './routing-modal';
import { ModalView } from '../reducers/modal';

import fetchGql from '../util/fetchGql';
import gql from '../util/gql';

export const SET_LOGIN_STATUS = 'SET_LOGIN_STATUS';
export const SET_USER = 'SET_USER';

interface SetLoginStatus extends Action<typeof SET_LOGIN_STATUS> {
	status: RequestStatus;
	message?: string;
}

interface SetUser extends Action<typeof SET_USER> {
	user?: User;
}

export type UserActions = SetLoginStatus | SetUser;

export function isCurrentUserExpired(): boolean {
	try {
		const user = getAuthUser();
		if (user) {
			return false;
		}
		return true;
	} catch {
		return true;
	}
}

export const logout = (): ThunkActionRoot => (dispatch) => {
	window.localStorage.removeItem(LSAuthUser);
	dispatch({ type: SET_LOGIN_STATUS, status: RequestStatus.UNSUBMITTED });
	dispatch({ type: SET_USER, user: undefined });
	void dispatch(navigateModal(ModalView.LOGIN));
};

const LOGIN_QUERY = gql`
	query ($username: String!, $password: String!) {
		authenticate(username: $username, password: $password) {
			authToken
			id
			email
			username
			fullname
		}
	}
`;

export const login =
	(username: string, password: string): ThunkActionRoot<Promise<void>> =>
	async (dispatch) => {
		dispatch({
			type: SET_LOGIN_STATUS,
			status: RequestStatus.LOADING,
		});
		try {
			const response = await fetchGql({ query: LOGIN_QUERY, variables: { username, password } });
			const { data } = response;
			if (data.authenticate?.authToken) {
				window.localStorage.setItem(LSAuthUser, JSON.stringify(data.authenticate));

				dispatch({ type: SET_USER, user: data.authenticate });

				dispatch({ type: SET_LOGIN_STATUS, status: RequestStatus.SUCCESS });

				const expiresMillis = decodeAuthToken(data.authenticate?.authToken).exp * 1000;
				setTimeout(() => {
					if (isCurrentUserExpired()) {
						dispatch(logout());
					}
				}, expiresMillis - Date.now());
			}
			if (!data.authenticate) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				const errorMessage =
					response.errors?.[0]?.message?.replace(/Exception.*:/g, '') || 'Login Failed';
				dispatch({ type: SET_LOGIN_STATUS, status: RequestStatus.ERROR, message: errorMessage });
			}
		} catch (error) {
			console.error(error);
			dispatch({ type: SET_LOGIN_STATUS, status: RequestStatus.ERROR });
		}
	};
